.color-w{
  color: @color_w;
}

// WELCOME
.welcome{
  display: flex;
  height: 100vh;
  background-size: cover !important;
  position: relative;
  flex-direction: column;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  z-index: 3;

  .resolution-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
  }

  .title{
    > *{
      font: @f_bold 50px/120% @font;
      text-align: center;

      @media only screen and (max-width: 640px),
      only screen and (max-device-width: 640px) {
        font: @f_bold 38px/120% @font;
        text-align: center;
      }
    }

    margin-bottom: @gap*3;
  }

}

.video-bg {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 0;
  background: url("@{img}video-bg.jpg") 50% 90% no-repeat;
  background-size: cover !important;

  &::after{
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(@color_b1, .8);
    background: linear-gradient(37deg, rgba(#1d1c5c, .8) 0, rgba(#065d5b, .8) 100%);
  }

  video{
    //position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

body:not(.mobile){
  .section-state-list{
    .state-list{
      &__item{
        a{
          &:hover{
            transform: scale(1.05);
            box-shadow: 0 6px 36px 0 rgba(0,0,0, .1);
            z-index: 5;

            .state-flag{
              filter: grayscale(0) ;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.glass-bg{
  background: rgba(0,0,0, .2);
  backdrop-filter: blur(10px);
}

.fact-list{
  width: 100%;
  max-width: 800px;
  height: auto;
  //border: 1px solid red;
  margin: auto;
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios

  @media only screen and (max-width: 860px),
  only screen and (max-device-width: 860px) {
    flex-direction: column;
  }

  &__list{
    display: flex;
    flex-direction: column;
    flex: auto 1 1;

    @media only screen and (max-width: 860px),
    only screen and (max-device-width: 860px){
      width: 100%;
      order: 2;
    }

    &__icon{
      display: flex;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      background: @color_1;
      margin-right: @gap;
      width: 160px;
      min-width: 160px;
      height: 120px;

      svg{
        fill: @color_w;
      }

      img{
        width: 120px;
        height: 120px;
      }

      @media only screen and (max-width: 540px),
      only screen and (max-device-width: 540px) {
        width: 120px;
        min-width: 120px;
        height: 80px;

        img{
          width: 80px;
          height: 80px;
        }
      }
    }

    &__item{
      width: 100%;
      background: rgba(@color_w, .1);
      border-radius: 4px;
      padding-right: @gap;
      backdrop-filter: blur(5px);
      min-height: 120px;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: flex-start; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      color: @color_w;
      box-shadow: inset 0 1px 0 0 rgba(@color_w, .08), 0 6px 30px -4px rgba(@color_b1, .3);

      @media only screen and (max-width: 540px),
      only screen and (max-device-width: 540px) {
        min-height: 80px;
      }

      &::after{
        content: '';
        position: absolute;
        z-index: 3;
        left: 20%;
        top: 0;
        width: 300px;
        height: 100%;
        opacity: 0;
        background: linear-gradient(to right,rgba(@color_w,0) 0,@color_w 49%,rgba(@color_w,0) 100%);
        animation: shineRight 10s infinite ease-out;
      }
      
      p{
         font: @f_reg 18px/120% @font;
      }

      &:not(:last-child){
        margin-bottom: @gap;
      }
    }
  }
  
  &__sum{
    flex: 1 1 200px;
    color: @color_w;

    @media only screen and (max-width: 860px),
    only screen and (max-device-width: 860px){
      order: 1;
      flex: 1 1 auto;
      margin-bottom: @gap*2;
    }

    h1, h2{
       font: @f_reg 42px/120% @font;

      @media only screen and (max-width: 640px),
      only screen and (max-device-width: 640px) {
        font: @f_reg 32px/120% @font;
      }
    }
  }

  .curly-brackets{
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    width: 120px;
    position: relative;

    @media only screen and (max-width: 860px),
    only screen and (max-device-width: 860px) {
      display: none;
    }

    svg{
      height: 400px;
      fill: @color_2;
    }
  }

  &.fact-list__reverse{
    flex-direction: row-reverse;

    @media only screen and (max-width: 860px),
    only screen and (max-device-width: 860px){
      flex-direction: column;
    }

    .curly-brackets{
      svg{
        transform: scaleX(-1);
      }
    }
  }
}

// anim
.shineRight_anim{
  0%, 10%{
    opacity: .05;
    left: -900px;
  }
  30%{
    opacity: 0.12;
    left: 900px;
  }
  31%{
    opacity: 0;
    left: 900px;
  }
  32%,100%{
    opacity: 0;
    left: -900px;
  }
}
/* fadeIn and fadeOut */
@-webkit-keyframes shineRight{
  .shineRight_anim;
}
@keyframes shineRight{
  .shineRight_anim;
}
.gb1:after{
  animation-delay: 0.05s;
}
.gb2:after{
  animation-delay: 0.10s;
}
.gb3:after{
  animation-delay: 0.15s;
}

section{
  position: relative;
  z-index: 3;
}

.deeply-rooted{
  background: linear-gradient(37deg, rgba(#1d1c5c, 1) 0, rgba(#065d5b, 1) 100%);
  min-height: 300px;
  position: relative;

  @media only screen and (max-width: 560px),
  only screen and (max-device-width: 560px) {
    width: 100%;
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios


  }

  &__wrap{
    display: flex;
    justify-self: stretch;

  }

  &__map{
    width: 50%;
    display: flex;
    justify-content: flex-start; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      box-shadow: 300px 0 0 0 rgb(255 255 255);
    }

    @media only screen and (max-width: 640px),
    only screen and (max-device-width: 640px) {
      min-width: 320px;
    }

    @media only screen and (max-width: 560px),
    only screen and (max-device-width: 560px) {
      position: absolute;
      opacity: .25;
      top:0;
    }
  }

  &__description{
    width: 50%;
    display: flex;
    justify-content: flex-end; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    @media only screen and (max-width: 560px),
    only screen and (max-device-width: 560px) {
      width: 100%;
      margin: auto;
    }

    &__text{
      max-width: 360px;
      padding-left: @gap;
      font: @f_reg 20px/125% @font;
      color: @color_w;

      @media only screen and (max-width: 760px),
      only screen and (max-device-width: 760px) {
        font: @f_reg 16px/125% @font;
      }
    }
  }
}

.interactive-map{
  background: @color_1;
  padding-top: @gap*3;
  padding-bottom: @gap*3;
  
  @media only screen and (max-width: 840px),
  only screen and (max-device-width: 840px) {
    display: none;
  }

  .tooltip{
    opacity: 0;
    visibility: hidden;
    display: flex;
    border-radius: @br;
    border: 1px solid @color_b5;
    background: @color_w;
    backdrop-filter: blur(10px);
    box-shadow: @shadow_2;
    padding: 16px 20px;
    position: absolute;
  }

  &.mouseover .tooltip{
    opacity: 1;
    visibility: visible;
  }

  svg{
    width: 100%;

    path{
      transition: all .2s linear;
      position: relative;
    }

    path:hover{
      cursor: pointer;
      fill: @color_b4 !important;
      z-index: 5;
      box-shadow: 0 10px 30px -4px rgba(0,0,0,.5);
      //transform: scale(1.1) matrix(0.6723592315570887 0 0 0.6723592315570887 117.05175647693603 -6.726925385443138) !important;
    }
  }
}
body.mobile .main-container .page-content .interactive-map{
  display: none !important;
}

.section-state-list {
  padding: @gap*3 @gap;
  background: @color_w;

  .state-list{
    display: flex;
    flex-wrap: wrap;
    margin-left: -@gap/2;
    margin-right: -@gap/2;

    &__item{
      width: 20%;
      display: flex;
      padding: 0 @gap/2;
      margin-bottom: @gap;

      @media only screen and (max-width: 1190px),
      only screen and (max-device-width: 1190px) {
        width: 25%;
      }

      @media only screen and (max-width: 960px),
      only screen and (max-device-width: 960px) {
        width: 33.33%;
      }

      @media only screen and (max-width: 720px),
      only screen and (max-device-width: 720px) {
        width: 50%;
      }

      @media only screen and (max-width: 480px),
      only screen and (max-device-width: 480px) {
        width: 100%;
        margin-bottom: 4px;
      }

      a{
        background: rgba(@color_w , .98);
        display: flex;
        justify-content: flex-start; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios


        position: relative;
        padding: @gap /2 @gap @gap /2 80px;
        min-height: 60px;
        text-decoration: none;
        box-shadow: @shadow_2;
        border: 1px solid @color_b5;
        backdrop-filter: blur(20px);
        border-radius: @br;
        width: 100%;
        color: @color_b1;
        line-height: 113%;

        @media only screen and (max-width: 480px),
        only screen and (max-device-width: 480px) {
          font-size: 17px;
        }

        @media only screen and (max-width: 560px),
        only screen and (max-device-width: 560px) {
          font-size: 15px;
          min-height: 54px;
          padding: @gap /2 @gap /2 @gap /2 72px;
        }

        .state-flag{
          display: flex;
          position: absolute;
          left: @gap;
          top: @gap/1.3;
          overflow: hidden;
          border-radius: 4px;
          order: 0;
          width: 54px;
          min-width: 54px;
          max-width: 54px;
          height: 32px;
          filter: grayscale(20%) ;
          opacity: .9;
          transition: all .2s linear;

          @media only screen and (max-width: 560px),
          only screen and (max-device-width: 560px) {
            left: @gap /1.5;
          }

          img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
            left: 0;
            top: 0;

          }

          @media only screen and (max-width: 560px),
          only screen and (max-device-width: 560px) {
            width: 48px;
            min-width: 48px;
            max-width: 48px;
            height: 27px;
          }
        }
      }
    }
  }


}

.bg-illustration{
  padding-bottom: 260px;
  background: @color_w url("@{img}footer.svg") 50% 100% no-repeat;
  background-size: auto 200px !important;
}

body:not(.mobile){
  .section-state-list{
    .state-list{
      &__item{
        a{
          &:hover{
            transform: scale(1.05);
            box-shadow: 0 6px 36px 0 rgba(0,0,0, .1);
            z-index: 5;

            .state-flag{
              filter: grayscale(0) ;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

// arrow down
.arrow_down{
  display: block;
  width:60px;
  height: 60px;
  color:rgba( @color_w, 0.5);
  position: fixed;
  z-index: 2;
  bottom: 5px;
  left: 50%;
  margin-left: -30px;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios

  &::before{
    content: "\f078";
    .fal;
    font-size: 22px;
  }
}

.slow-jump{
  animation:          slowjump 2.8s infinite linear;
}

@keyframes slowjump{
  0%,100% {
    transform:          translateY(0);
  }

  50% {
    transform:          translateY(-10px);
  }
}
