.footer{
  width: 100%;
  position: relative;
  z-index: 3;
  background: @color_b1;
  background: linear-gradient(37deg, @color_b1 - 10% 0, @color_b1 - 30% 100%);

  &__logo{
    opacity: .7;

    img, svg{
      height: 40px;
      //filter: brightness(200%) saturate(0%) opacity(40%);

      .logo__symbol{
        fill:  @color_w; //@color_1;

      }
      .logo__text{
        fill:  @color_w; // @color_b1;

      }
    }
  }

  &__copyright{
    color: rgba(@color_w, .5);
    position: absolute;
    bottom: 3px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;

    @media only screen and (max-width: 740px),
    only screen and (max-device-width: 740px) {
      order: 99;
      margin-bottom: 0 !important;
    }

  }

  &__nav{
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;

    @media only screen and (max-width: 740px),
    only screen and (max-device-width: 740px) {
      flex-direction: column;
    }

    a{
      text-decoration: none;
      color: rgba(@color_w, .8);
      font: @f_reg 15px/120% @font;
      margin-left: @gap;
      padding: 10px 0;

      @media only screen and (max-width: 740px),
      only screen and (max-device-width: 740px) {
        font: @f_reg 13px/120% @font;
        padding-left: 0 !important;
        margin: 0 10px !important;
      }

      &:hover{
        text-decoration: underline;
      }
    }
  }

  .resolution-wrap{
    align-content: center;
    align-items: center;
    display: flex;
    padding-top: @gap*1.6;
    padding-bottom: @gap*1.6;
    justify-content: space-between;
    flex-wrap: wrap;

    @media only screen and (max-width: 760px),
    only screen and (max-device-width: 760px) {
      flex-direction: column;

      > *{
        margin-bottom: @gap;
      }

    }
  }
}