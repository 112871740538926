// Стили Выделения на странице
::selection {
  background: rgba(@color_b1, .8);
  //background: @gradient_1;
  color: @color_w !important;
}
::-moz-selection {
  background:  rgba(@color_b1, .8);
  //background: @gradient_1;
  color: @color_w !important;
}

// Общие параметры анимации
//=============================================================================
@anim: all .2s ease-out;
.anim_on(@anim){
  transition: @anim;
}

// ЦВЕТА
//=============================================================================
// основные цвета:
//#DAD507 3.01%, #19A852 85.92%
@color_1: #3498DB;
@color_2: @color_1 + 20%;
@color_3: #065d5b;
@color_4: #3FA9F5;
@color_5: #E33D2B;

// градиент серого:
@color_b1: #2c3e50;
@color_b2: #616B74;
@color_b3: #AFB8BF;
@color_b4: #DBDFE2;
@color_b5: #F4F5F5;
@color_w: #fff;


// Основные шрифты
//=============================================================================
@font: 'Ubuntu', Helvetica, Arial, sans-serif;
@icons: 'Icons';
html, body {
  font: @font_6;
  line-height: 130%;
  color: @color_b1;
}

@font_1: @f_sbold 35px/120% @font;
@font_2: @f_sbold 25px/120% @font;
@font_3: @f_sbold 23px/120% @font;
@font_4: @f_reg 25px/140% @font;
@font_5: @f_reg 21px/140% @font;
@font_6: @f_reg 17px/140% @font;
@font_7: @f_reg 15px/140% @font;
@font_8: @f_reg 13px/140% @font;
@font_9: @f_reg 11px/140% @font;

@f_reg: 300;
@f_sbold: 500;
@f_bold: 700;


// bind objects
//=============================================================================
// BORDER RADIUS
@br: 6px;

// BOX SHADOW
@shadow: 0 7px 35px -2px rgba(0,0,0, .09);
@shadow_2: 0 7px 27px -3px rgba(0,0,0,.07);
.box-shadow (@shadow){
  box-shadow: @shadow;
}

// ВЫСОТА КНОПОК, ИНПУТОВ, И Др. ЭЛЕМЕНТОВ
//=============================================================================
@gap: 16px;
@elements_h: 54px;
@img: '/assets/core/img/';
@gallery: '/assets/core/img/gallery/';

// TEXT ALIGN
.ta_l {
  text-align: left !important;
}

.ta_r {
  text-align: right !important;
}

.ta_c {
  text-align: center !important;
}

// HOVER EFFECTS
.h_zoom(@zoom, @sp) {
  transform: scale(@zoom);
  transition: all @sp ease-out;
}

// NO SELECT TEXT
.no_select,
.no_select * {
  cursor: default;
  user-select: none !important;
}


// CUSTOM SCROLLBAR
//=============================================================================
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: @color_b5;
}

@scroll_thumb: @color_b3;
@scroll_thumb_s: rgba(@color_b5, 0);
::-webkit-scrollbar-thumb {
  background: @scroll_thumb;
  border-radius: @br;

  &:hover {
    background: @scroll_thumb - 20%;
  }

  &:active {
    background: @color_1;
  }
}


// ANIMATION HOVER LINKS and BUTTONS
//=============================================================================
select,
a, button,
*:before,
*:after,
[type="button"],
[type="submit"],
[type="reset"] {
  transition: all .25s ease-in-out;
}
a{
  color: @color_2;

  &:hover{
    color: @color_2 + 20%;
  }
}


// ALIGN CENTER CSS
//=============================================================================
.imgfit {
  overflow: hidden;

  img {
    -o-object-fit: cover !important;
    object-fit: cover !important;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
  }
}


// FLEX-BOX ALIGN
.centerxy {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.centerx {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.centery {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}













