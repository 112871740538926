
// BUTTONS style
//=============================================================================
.btn__theme-1 {
  display: flex;
  border: none;
  background: none;
  outline: none;
  border-radius: @br !important;
  font: @f_sbold 20px/120% @font;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 120px;
  height: @elements_h;
  padding: 0 @gap*2;
  background: @color_2;
  text-align: center;
  text-decoration: none;
  color: @color_w;
  white-space: nowrap;
  .anim_on(@anim);
  box-shadow: 0 4px 18px 0 rgba(@color_2, .10);

  > *:not(:last-child) {
    margin-right: 7px;
  }

  > i{
    margin-left: 8px;
  }

}

.btn__theme-2 {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 120px;
  height: @elements_h;
  padding: 0 @gap*1.5;
  text-align: center;
  text-decoration: none;
  // border: 1px solid @color_1;
  box-shadow: inset 0 0 0 1px rgba(@color_2, 1);
  background: none;
  color: @color_b1 !important;
  white-space: nowrap;
  .anim_on(@anim);
  border-radius: @br;
  background: @color_2;
  .text-gradient;
  position: relative;

  &::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: @br;
    box-shadow: inset 1px 1px 0 0 rgba(@color_3, 1)

  }


  > *:not(:last-child) {
    margin-right: 7px;
  }

  &:active {
    transform: translateY(1px);
  }
}

.btn__theme-3 {
  .centerxy;
  width: auto;
  height: @elements_h;
  padding: 0 @gap*1.5;
  text-align: center;
  text-decoration: none;
  border: 1px solid @color_w;
  color: @color_b1;
  background: @color_w;
  white-space: nowrap;
  .anim_on(@anim);
  border-radius: @br;
  .box-shadow(@shadow);

  &:active {
    transform: translateY(1px);
  }

}

.btn__theme-4 {
  .centerxy;
  width: auto;
  height: @elements_h;
  padding: 0 @gap*1.5;
  text-align: center;
  text-decoration: none;
  border: 1px solid @color_w;
  color: @color_w;
  background: none;
  white-space: nowrap;
  transition: all .2s linear !important;
  border-radius: @br;

  &:active {
    transform: translateY(1px);
  }

}

[class*="style-btn__"] {
  display: flex;
  display: -ms-flexbox;
  .no_select;

  > * {
    i {
      margin-right: 10px;
    }
  }
}

.style-btn__1 {
  > * {
    .btn__theme-1;
  }
}

.style-btn__2 {
  > * {
    .btn__theme-2;
  }
}

.style-btn__3 { // white
  > * {
    .btn__theme-3;
  }
}


// HOVER not mobile
body:not(.mobile) {
  .style-btn__1 > *:not([disabled]):hover,
  .btn__theme-1:not([disabled]):hover {
    cursor: pointer !important;
    background: @color_2;
    background: @color_2;
    color: @color_w;
    box-shadow: 0 2px 20px 0 rgba(@color_1, .45), inset 0 0 30px 0 rgba(@color_3, .55);
  }

  .style-btn__2 > *:not([disabled]):hover,
  .btn__theme-2:not([disabled]):hover {
    cursor: pointer !important;
    //background: @color_2;
    box-shadow: inset 0 0 0 1px rgba(@color_3, 1);
    color: @color_w !important;
    background: @color_2;
    .text-gradient;
  }

  .style-btn__3 > *:not([disabled]):hover,
  .btn__theme-3:not([disabled]):hover {
    cursor: pointer !important;
    background: @color_w;
    color: @color_b1 !important;
  }

  .style-btn__4 > *:not([disabled]):hover,
  .btn__theme-4:not([disabled]):hover {
    cursor: pointer !important;
    background: none;
    box-shadow: inset 0 0 0 5px rgba(@color_w, 1);
    color: @color_w !important;
  }
}

.btns_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: stretch;
}

.btn_l {
  display: flex;
  justify-content: flex-start !important;
}

.btn_c {
  display: flex;
  justify-content: center !important;
}

.btn_r {
  display: flex;
  justify-content: flex-end !important;

}


// SCROLL UP BUTTON
// =======================================
#go-top {
  position: fixed;
  bottom: 65px;
  right: 10px;
  display: none;
  width: 40px;
  height: 40px;
  z-index: 99;

  @media only screen and (max-width: 480px),
  only screen and (max-device-width: 480px) {
    bottom: 85px;
  }

  > * {
    display: flex;
    text-align: center;
    cursor: pointer;
    width: inherit;
    height: inherit;
    background: @color_1;
    border: 1px solid @color_1;
    collapse: @color_2;
    border-radius: @br;
    transform: scale(0.95);
    text-decoration: none;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios



    &::after {
      content: '\f077';
      font-size: 15px;
      .fal;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      
      
      color: @color_w;

    }

    &:active {
      transform: scale(0.92);
    }
  }
}

.btn-more{
  display: inline-flex;
  min-height: @elements_h /1.2;
  color: @color_1;
  font: @f_reg 20px/110% @font;
  //border-radius: @br;
  user-select: none;
  padding: 10px 0;
  text-decoration: none;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-transform: lowercase;
  transition: all .2s linear;
  background: @color_2;
  .text-gradient !important;
  position: relative;

  &:focus, &:active{
    //box-shadow: inset 0 0 0 1px @color_1 !important;
    color: @color_1 !important;
    background: @color_2;

    &::after{
      color: @color_1 !important;
    }
  }

  &:hover{
    //box-shadow: inset 0 0 0 100px @color_1;
    color: white;
    cursor: pointer;
    background: @color_2;
    padding-left: 3px;

    &::after{
      color: white;
      margin-left: 13px;
    }
  }
  &:active{
    transform: translateY(2px);
  }

  &::after{
    content: 'V';
    padding-top: 2px;
    position: relative;
    top: 2px;
    .font-icon;
    margin-left: 10px;
  }
}


