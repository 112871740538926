#page_preloader {
  position: fixed;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 105%;
  height: 105vh;
  left: 50%;
  top: 50%;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: @color_w;
  transform: translate(-50%, -50%);

  > div{
    display: flex;
    width: 80px;
    height: 80px;
    margin: auto;
    //background: url("@{img}loader.png") 50% 50% no-repeat;
    //background-size: cover !important;
    //animation: preload 3s infinite linear;
    animation: preload 1.2s forwards linear;

    svg{
      width: 80px;
      height: 80px;
      animation: zoomer 3s infinite linear;

    }
  }

}
@keyframes zoomer {
  0%, 100%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.2);
  }
}




//============================================================================
//+++++++++++++++++++++++++++++++ RESET CSS ++++++++++++++++++++++++++++++++++
//============================================================================
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-weight: @f_reg;
}


// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
  display: block;
}


// Display block in IE6-9 and FF3
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}


// Prevents modern browsers from displaying 'audio' without controls
audio:not([controls]) {
  display: none;
}


// Base settings
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
}


// Prevents sub and sup affecting line-height in all browsers
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

// Img border in a's and image quality
img {
  //max-width: 100%; // Make images inherently responsive
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}


// Forms
//==================================================================

// Font size in all browsers, margin changes, misc consistency
button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}
button,
input {
  *overflow: visible; // Inner spacing ie IE6/7
  line-height: normal; // FF3/4 have !important on line-height in UA stylesheet
}
button::-moz-focus-inner,
input::-moz-focus-inner { // Inner padding and border oddities in FF3/4
  padding: 0;
  border: 0;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer; // Cursors on all buttons applied consistently
  -webkit-appearance: button; // Style clickable inputs in iOS
}
input[type="search"] { // Appearance in Safari/Chrome
  box-sizing: content-box;
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; // Inner-padding issues in Chrome OSX, Safari 5
}
textarea {
  overflow: auto; // Remove vertical scrollbar in IE6-9
  vertical-align: top; // Readability and alignment cross-browser
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;

  &:before,
  &:after{
    content: '';
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  padding: 0;
  margin: 0;
}

b, strong {
  &, & *{
    font-weight: @f_sbold !important;
  }
}

// if not fontawersome
i,
em{
  &:not(.fas):not(.fa):not(.far):not(.fab):not(.fal):not(.fad):not(.font-icon){
    font-style: italic !important;
  }

}
hr {
  display: block;
  width: 100%;
  height: 10px;
  visibility: hidden;
  opacity: 0;
  clear: both;
  float: none;
  margin: 0 !important;
  padding: 0 !important;
}

// NO PADDING in WIDTH
//==================================================================
*, *:after, *:before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}



