.font_8, .font_8 * {
  font: @font_8;
}

.font_7, .font_7 * {
  font: @font_7;
}

.font_6, .font_6 * {
  font: @font_6;
}

.font_5, .font_5 * {
  font: @font_5;
}

.font_4, .font_4 * {
  font: @font_4;
}

.font_3, .font_3 * {
  font: @font_3;
}

.font_2, .font_2 * {
  font: @font_2;
}

.font_1, .font_1 * {
  font: @font_1;
}

.caps {
  text-transform: uppercase;
}

.headernames_box {

  > h1 {
    .font_2;
    margin-bottom: 10px;

    @media only screen and (max-width: 860px),
    only screen and (max-device-width: 860px) {
       font: @f_reg 32px/120% @font;
    }
  }

  > h2 {
    .font_2;
    margin-bottom: 10px;
  }

  > h3 {
    .font_3;
    margin-bottom: 10px;
  }

  > h4 {
    .font_4;
    margin-bottom: 10px;
  }

}


// TEXT BOX
.text-box , .text-wrap{

  .row, .logos{
    display: flex;
    flex-wrap: wrap;

    margin-left: -@gap/2 !important;
    margin-right: -@gap/2 !important;

    &[data-columns='1'] > .column{
      width: 100% !important;
    }
    &[data-columns='2'] > .column{
      width: 50% !important;

      @media only screen and (max-width: 580px),
      only screen and (max-device-width: 580px) {
        width: 100% !important;
      }
    }
    &[data-columns='3'] > .column{
      width: 33.33% !important;

      @media only screen and (max-width: 640px),
      only screen and (max-device-width: 640px) {
        width: 100% !important;
      }
    }
    &[data-columns='4'] > .column{
      width: 25% !important;

      @media only screen and (max-width: 780px),
      only screen and (max-device-width: 780px) {
        width: 50% !important;
      }
      @media only screen and (max-width: 580px),
      only screen and (max-device-width: 580px) {
        width: 100% !important;
      }
    }
  }
  .row{
    margin-bottom: @gap;

    > .column{
      padding-left: @gap/2 !important;
      padding-right: @gap/2 !important;

      display: flex;
      flex-wrap: wrap;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-bottom: @gap;

      .img-wrap{
        position: relative;
        display: flex;
        overflow: hidden;
        padding-bottom: 60%;
        width: 100%;
        height: auto;

        img{
          position: absolute;
          width: 100% !important;
          height: 100%;
          object-fit: cover;
          font-family: 'object-fit: cover;';
          left: 0;
          top: 0;
        }
      }
    }
  }
  .logos{
    margin-bottom: @gap*2;

    .img-wrap{
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      align-content: center;
      align-items: center;
      justify-content: center;
      background: none !important;

      img{
        position: relative;
        width: auto !important;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }
  }

  .my-img{
    padding: 0;
  }

  .video-wrap{
    display: flex;
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    align-content: center;
    align-items: center;
    justify-content: center;

    iframe{
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }

  .title {
    > * {
      .font_1;
      padding-left: 20px;
      position: relative;
      margin-bottom: 8px;

      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        background: @color_2;
        display: block;
        width: 2px;
        height: calc(100% - 16px);
        transform: translateY(-50%);
      }
    }
  }

  h1, h2, h3, h4 {
    display: inline-block;
    width: 100%;
  }
  h1{
    font: @f_sbold 50px/120% @font;
    letter-spacing: 0.14px;
    position: relative;
    margin-bottom: 8px;

    @media only screen and (max-width: 860px),
    only screen and (max-device-width: 860px) {
      font: @f_sbold 32px/120% @font;
    }
    @media only screen and (max-width: 560px),
    only screen and (max-device-width: 560px) {
      font: @f_sbold 28px/120% @font;
    }
  }
  h2{
    font: @f_sbold 32px/120% @font ;
    position: relative;
    margin-bottom: 8px;

    @media only screen and (max-width: 860px),
    only screen and (max-device-width: 860px) {
      font: @f_sbold 25px/120% @font;
    }
    @media only screen and (max-width: 560px),
    only screen and (max-device-width: 560px) {
      font: @f_sbold 20px/120% @font;
    }
  }
  h3{
    font: @f_sbold 22px/120% @font !important;
    position: relative;
    margin-bottom: 8px;

    a{
      font: @f_sbold 22px/120% @font !important;
      color: @color_b1;

      &:hover{
        color: @color_2;
      }
    }

    @media only screen and (max-width: 860px),
    only screen and (max-device-width: 860px) {
      font: @f_sbold 20px/120% @font;
    }
    @media only screen and (max-width: 560px),
    only screen and (max-device-width: 560px) {
      font: @f_sbold 18px/120% @font;
    }
  }
  h4{
    font: @f_sbold 19px/116% @font !important;
    position: relative;
    margin-bottom: 8px;

    a{
      font: @f_sbold 19px/116% @font !important;
      color: @color_2;

      &:hover{
        color: @color_2 + 20%;
      }
    }

    @media only screen and (max-width: 860px),
    only screen and (max-device-width: 860px) {
      font: @f_sbold 18px/120% @font;
    }
  }
  .date{
    color: @color_b2;
    margin-bottom: @gap;

    &::before{
      content: '\4d';
      color: @color_b3;
      .font-icon;
    }
  }

  .sub-title {
    > * {
      .font_3;
      margin-bottom: 10px;
    }
  }

  .sub-sub-title {
    .font_4;
  }

  p{
    .font_6;
    margin-bottom: @gap;

    @media only screen and (max-width: 960px),
    only screen and (max-device-width: 960px) {
      font: @f_reg 15px/120% @font;
    }
  }
  * p{
    margin-bottom: 0 !important;
  }

  .big-img{
    //overflow: hidden;
    max-width: 100%;
    display: flex;
    border-radius: @br;
    .box-shadow(@shadow);
    //padding-bottom: 56.25%;
    position: relative;
    margin-bottom: @gap;

    img{
      width: 100%;
      height: 100%;
      -o-object-fit: cover !important;
      object-fit: cover !important;
      font-family: 'object-fit: cover;';
      //position: absolute;
      //top: 0;
      //left: 0;
    }
  }

  [class*='prev-img']{
    width: 33.33%;
    display: flex;

    > div{
      overflow: hidden;
      display: flex;
      flex: 1 0 120px;
      width: 100%;
      padding-bottom: 66.25%;
      position: relative;
      margin-bottom: @gap;
      border-radius: @br;
      .box-shadow(@shadow);

      img{
        width: 100%;
        height: 100%;
        -o-object-fit: cover !important;
        object-fit: cover !important;
        font-family: 'object-fit: cover;';
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &.prev-img__left{
      float: left;
      margin-right: @gap;
    }
    &.prev-img__right{
      float: right;
      margin-left: @gap;
    }
  }

  .quote,
  .quote_c,
  blockquote {
    position: relative;
    margin-bottom: @gap;
    font-size: 16px;
    display: inline-block;
    width: 100%;
    background: @color_b5 + 5%;
    border-radius: @br/2;
    padding: 20px 20px 20px 60px;
    box-shadow: @shadow;
  }

  .quote_c {
    text-align: center;
    padding-left: 20px !important;
  }

  .quote,
  blockquote {
    &::before {
      content: "";
      width: 40px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      background: @color_b3;
      border-radius: @br/2 0 0 @br/2;
    }

    &::after {
      content: "\49"; //.dripicons-broadcast
      width: 40px;
      height: 40px;
      position: absolute;
      color: rgba(@color_b2, 1);
      .font-icon;
      .centerxy;
      font-size: 23px;
      top: 20px;
      left: 8px;
      z-index: 2;
      display: block;
    }
  }

  ul:not(.todo-list):not(.numeric-list){
    list-style-type: none;
    counter-reset: num;
    margin: 0 auto @gap auto;

    > li {
      position: relative;
      list-style: none;
      min-height: 25px;
      padding-left: 18px;

      &:not(:last-of-type){
        margin-bottom: @gap;
      }

      &:before {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        left: 2px;
        top: 7px;
        z-index: 3;
        background: rgba(@color_2 , .7);
        border-radius: 100%;
      }

      > * {
        margin: 10px auto 5px;

        > li {

          &:before {
            content: '';
            width: 8px;
            height: 8px;
            position: absolute;
            left: 2px;
            top: 7px;
            z-index: 3;
            background: @color_b3;
            border-radius: 100%;
          }
        }
      }
    }
  }

  ul.todo-list{
    list-style-type: none;
    counter-reset: num;
    margin: 0 auto @gap *2 auto;

    > li {
      position: relative;
      list-style: none;
      margin-bottom: 15px;
      min-height: 25px;
      padding-left: 18px;

      label {
        position: absolute;
        left: 0;
        top: -2px;
        margin-right: 10px;
      }
    }
  }

  ol {
    list-style-type: none;
    counter-reset: num;
    margin: 0 auto @gap auto;

    > li {
      position: relative;
      list-style: none;
      margin-bottom: 15px;
      min-height: 25px;
      padding-left: 30px;

      &::before {
        content: counter(num) ' ' !important;
        counter-increment: num !important;
        color: @color_w;
        background: @color_2;
        width: 23px;
        height: 23px;
        display: flex;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        position: absolute;
        line-height: 17px;
        left: 0;
        top: 0px;
        font-size: 12px;
        font-weight: @f_bold;
        border-radius: @br;
      }
    }
  }

  .action-color{
    color: @color_1;
  }

  .anchors{
    a{
      text-decoration: none;
      color: @color_b4;
      border-bottom: 1px dashed rgba(@color_w, .2);

      &:hover{
        border-bottom: 1px dashed rgba(@color_w, .8);
      }
    }
  }
  .numeric-hn{
    position: relative;
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    margin-bottom: @gap;

    > span {
      display: flex;
      align-self: stretch;
      min-height: 100%;
      align-content: center;
      align-items: center;
      font: @f_sbold 32px/120% @font;
    }

    @media only screen and (max-width: 540px),
    only screen and (max-device-width: 540px) {
      > span{
        font: @f_sbold 20px/120% @font !important;
      }
    }

    &::before {
      content: attr(data-numeric);
      margin-right: @gap;
      color: @color_2;
      display: flex;
      flex:  0 0 46px;
      width: 46px;
      min-width: 46px;
      height: 46px;
      align-content: center;
      align-items: center;
      justify-content: center;
      position: relative;
      line-height: 17px;
      left: 0;
      top: 0;
      font-size: 22px;
      font-weight: @f_bold;
      border-radius: @br;
    }
  }
  .numeric-list{

    > li{
      padding-left: 65px;
      margin-bottom: @gap *2;
      position: relative;

      > ul.numeric-list{
        > li{
          font: @f_reg 15px/125% @font !important;
          color: @color_b3;

          .numeric-list__number{
            font-size: 14px !important;
          }

          > ul.numeric-list{
            > li{
              padding-left: 90px !important;

              .numeric-list__number{
                font-size: 13px !important;
              }
            }
          }
        }
      }
    }

    &__number{
      margin-right: @gap;
      background: @color_b1 + 20%;
      display: inline-flex;
      width: auto;
      height: auto;
      align-content: center;
      align-items: center;
      justify-content: center;
      position: absolute;
      padding: 8px 11px 6px 13px;
      left: 0;
      top: -3px;
      color: @color_b3;
      font-size: 16px;
      line-height: 16px !important;
      font-weight: @f_sbold;
      border-radius: @br;
    }
  }

  .table-scroll{
    width: 100%;
    overflow: hidden;
    overflow-x: auto;

  }

  table.adaptive-table{
    border-radius: @gap;
    width: 100%;
    display: flex;
    flex-direction: column;

    thead{
      display: none;
    }

    tr{
      display: grid;
      border: 1px solid @color_b5 - 10%;
      grid-template-rows: auto;
      grid-template-columns: 1fr/2 1fr/2 1fr;
      background: @color_w;
      margin-bottom: @gap;
      border-radius: @gap;
      box-shadow: @shadow;
      padding: 6px @gap / 2 @gap / 2;

      @media only screen and (max-width: 740px),
      only screen and (max-device-width: 740px){
        grid-template-columns: 1fr auto auto;

      }
      @media only screen and (max-width: 560px),
      only screen and (max-device-width: 560px) {
        grid-template-columns: 1fr;
      }


      > td {
        font-size: 14px;
        display: inline-flex;
        flex-direction: column;
        padding: 6px @gap @gap;
        text-align: left;
        position: relative;

        &:nth-child(1) {
          grid-row-start: 1;
          grid-column-start: 1;
          grid-row-end: 2;
          grid-column-end: 2;
        }
        &:nth-child(2) {
          grid-row-start: 2;
          grid-column-start: 1;
          grid-row-end: 3;
          grid-column-end: 2;
        }
        &:nth-child(3) {
          grid-row-start: 3;
          grid-column-start: 1;
          grid-row-end: 4;
          grid-column-end: 2;
        }
        &:nth-child(4) {
          grid-row-start: 1;
          grid-column-start: 2;
          grid-row-end: 2;
          grid-column-end: 3;

        }
        &:nth-child(5) {
          grid-row-start: 2;
          grid-column-start: 2;
          grid-row-end: 3;
          grid-column-end: 3;

        }
        &:nth-child(6) {
          grid-row-start: 3;
          grid-column-start: 2;
          grid-row-end: 4;
          grid-column-end: 3;
        }
        &:nth-child(7) {
          grid-row-start: 1;
          grid-column-start: 3;
          grid-row-end: 4;
          grid-column-end: 4;
        }

        @media only screen and (max-width: 740px),
        only screen and (max-device-width: 740px) {
          &:nth-child(1) {
            grid-row-start: 1;
            grid-column-start: 1;
            grid-row-end: 2;
            grid-column-end: 2;
          }
          &:nth-child(2) {
            grid-row-start: 1;
            grid-column-start: 2;
            grid-row-end: 2;
            grid-column-end: 3;
          }
          &:nth-child(3) {
            grid-row-start: 1;
            grid-column-start: 3;
            grid-row-end: 2;
            grid-column-end: 4;
          }
          &:nth-child(4) {
            grid-row-start: 2;
            grid-column-start: 1;
            grid-row-end: 3;
            grid-column-end: 2;

          }
          &:nth-child(5) {
            grid-row-start: 3;
            grid-column-start: 1;
            grid-row-end: 4;
            grid-column-end: 2;

          }
          &:nth-child(6) {
            grid-row-start: 2;
            grid-column-start: 2;
            grid-row-end: 4;
            grid-column-end: 4;
          }
          &:nth-child(7) {
            grid-row-start: 4;
            grid-column-start: 1;
            grid-row-end: 5;
            grid-column-end: 4;
          }
        }

        @media only screen and (max-width: 560px),
        only screen and (max-device-width: 560px){
          &:nth-child(1) {
            grid-row-start: 1;
            grid-column-start: 1;
            grid-row-end: 2;
            grid-column-end: 2;
          }
          &:nth-child(2) {
            grid-row-start: 2;
            grid-column-start: 1;
            grid-row-end: 3;
            grid-column-end: 2;
          }
          &:nth-child(3) {
            grid-row-start: 3;
            grid-column-start: 1;
            grid-row-end: 4;
            grid-column-end: 2;
          }
          &:nth-child(4) {
            grid-row-start: 4;
            grid-column-start: 1;
            grid-row-end: 5;
            grid-column-end: 2;

          }
          &:nth-child(5) {
            grid-row-start: 5;
            grid-column-start: 1;
            grid-row-end: 6;
            grid-column-end: 2;

          }
          &:nth-child(6) {
            grid-row-start: 6;
            grid-column-start: 1;
            grid-row-end: 7;
            grid-column-end: 2;
          }
          &:nth-child(7) {
            grid-row-start: 7;
            grid-column-start: 1;
            grid-row-end: 8;
            grid-column-end: 2;
          }
        }

        &::before{
          content: attr(data-thn);
          display: flex;
          width: calc(100% + 26px);
          font: @f_sbold 16px/110% @font;
          //border-bottom: 1px solid @color_2;
          padding: 10px 13px;
          margin-bottom: 4px;
          background: @color_b5;
          border-radius: @br;
          left: -13px;
          position: relative;
        }

        &::after{
          content: '';
          width: 1px;
          height: 100%;
          //background: @color_2;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
        }
      }
    }
  }

}

.text-gradient{
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;

}

// Adaptive fonts
.adaptive-font(@pcSize, @mobSize) {
  @media only screen and (max-width: 767px),
  only screen and (max-device-width: 767px) {
    font-size: calc(@mobSize + ((@pcSize - @mobSize) + (@pcSize - @mobSize) * .7) * ((100vw - 320px) / @resolution)) !important;
  }
  @media only screen and (min-width: 767px),
  only screen and (min-device-width: 767px) {
    font-size: calc(@mobSize + (@pcSize - @mobSize) * (100vw / @resolution)) !important;
  }
}




// LISTS STYLE
//=============================================================================
[class*='list_v'] {
  margin: 0 auto 20px;

  li {
    position: relative;
    margin-bottom: 15px;
    padding-left: 25px;
  }
}

.list_v1 {
  li {

    &:before {
      content: '\53'; //.dripicons-checkmark
      width: 20px;
      height: 20px;
      position: absolute;
      left: -3px;
      top: 0;
      .font-icon;
      z-index: 3;
      .centerxy;
      font-size: 17px;
      text-rendering: auto;
      font-style: normal !important;
      color: @color_2;
    }
  }
}

.list_v2 {
  li {
    &:before {
      content: "\39"; // .dripicons-cross
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 4px;
      z-index: 3;
      .centerxy;
      font-size: 16px;
      text-rendering: auto;
      font-style: normal !important;
      color: @color_4 !important;
      .font-icon;
    }
  }
}

.list_v3 {
  list-style-type: none;
  counter-reset: num;
  margin-bottom: 40px;

  > li {
    list-style: none;
    margin-bottom: 15px;
    min-height: 25px;

    &::before {
      content: counter(num) ' ' !important;
      counter-increment: num !important;
      color: @color_w;
      background: @color_1;
      width: 25px;
      height: 25px;
      .centerxy;
      position: absolute;
      line-height: 17px;
      left: -8px;
      top: 0;
      font-size: 13px;
      font-weight: 600;
      border-radius: @br;

    }
  }
}

.list_v4 {
  li {
    padding: 0 0 14px 0;
    border-bottom: 1px solid@color_b5;
  }
}

.list_v5 {
  > li {
    margin-bottom: 10px;

    &::before {
      content: "";
      width: 6px;
      height: 6px;
      position: absolute;
      left:6px;
      top:8px;
      border-radius: 100%;
      z-index: 3;
      background: @color_2;
    }

    > ul {

      margin: 13px auto 0;

      > li {
        width: 100%;

        &::before {
          content: "";
          width: 6px;
          height: 6px;
          position: absolute;
          left:6px;
          top:8px;
          border-radius: 100%;
          z-index: 3;
          background: @color_b5;
        }
      }
    }
  }
}

.list_v6 {
  > li {
    margin-bottom: 8px;
    padding-left: 13px;

    &::before {
      content: '\e01c'; //.dripicons-media-record
      width: 10px;
      height: 10px;
      position: absolute;
      left: -3px;
      top: 3px;
      font-size: 6px;
      .font-icon;
      z-index: 3;
      .centerxy;
      color: @color_1;
    }

    > ul {

      margin: 13px auto 0;

      > li {
        width: 100%;

        &::before {
          content: '\e01c';
          background-position: 50% -20px * 18;
          width: 10px;
          height: 10px;
          position: absolute;
          left: -3px;
          top: 1px;
          font-size: 9px;
          .font-icon;
          z-index: 3;
          .centerxy;
          color: @color_b5;
        }
      }
    }
  }
}


