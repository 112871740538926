// ---------------------------------- 404
.section__error {
  display: flex;
  height: 100vh;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  flex-direction: column;
  padding: @gap*2 @gap !important;
  background: url("@{img}video-bg.jpg") 50% 50% no-repeat;
  background-size: cover !important;

  &::after{
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index:1;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(@color_b1, .8);
    background: linear-gradient(37deg, rgba(#1d1c5c, .9) 0, rgba(#065d5b, .9) 100%);
  }

  > * {
    position: relative;
    z-index: 3;
  }

  .section__error__number {
    font-size: 120px;
    color: @color_1;
    font-weight: @f_bold !important;
    line-height: 150%;
    display: flex;
    text-align: center;
  }

  .error__details {
    text-align: center;
    color: @color_w;
  }

}



